/* eslint-disable no-console */
import { ReactElement, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import * as S from './Rate.styles';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Heading } from 'components/atoms/Heading/Heading';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { MediaQuery } from 'styles/mediaQuery';

export interface RateProps {
  name: string;
  title: string;
  description: string;
  value?: number | string;
  setRateValue?: (value: number, title: string) => void;
  dots: number;
}

export const Rate = ({
  name,
  title,
  description,
  value,
  setRateValue,
  dots,
}: RateProps): ReactElement => {
  const [cantrate, setCantRate] = useState(false);

  const intl = useIntl();

  const stringValue = useMemo(() => {
    switch (value) {
      case -1:
        return intl.formatMessage({
          description: "Assessment - Rate - Can't Rate Copy",
          defaultMessage: 'Cannot rate',
        });
      case 0:
        return intl.formatMessage({
          description: 'Assessment - Rate - Not at all Copy',
          defaultMessage: 'Not at all',
        });
      case 25:
        return intl.formatMessage({
          description: 'Assessment - Rate - Slightly Copy',
          defaultMessage: 'Slightly',
        });
      case 50:
        return intl.formatMessage({
          description: 'Assessment - Rate - Moderately Copy',
          defaultMessage: 'Moderately',
        });
      case 75:
        return intl.formatMessage({
          description: 'Assessment - Rate - Very much Copy',
          defaultMessage: 'Very much',
        });
      case 100:
        return intl.formatMessage({
          description: 'Assessment - Rate - Extremely Copy',
          defaultMessage: 'Extremely',
        });
      default:
        return undefined;
    }
  }, [intl, value]);

  return (
    <Flex
      container
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
      gap={['24px', [MediaQuery.MIN_768, '40px']]}
    >
      <Flex flexBasis={['100%', [MediaQuery.MIN_768, 'calc((100% - 40px) * 0.5)']]}>
        <Heading type={HeadingType.H3}>{name}</Heading>
        {description && <S.StyledDesc>{description}</S.StyledDesc>}
      </Flex>
      <Flex
        container
        flexDirection="column"
        flexBasis={['100%', [MediaQuery.MIN_768, 'calc((100% - 40px) * 0.5)']]}
      >
        <S.StyledSlider
          value={value}
          title={title}
          setRateValue={setRateValue}
          cantRate={cantrate}
          dots={dots}
        />
        <Flex container justifyContent="space-between">
          {!stringValue ? null : <S.StyledValue>{stringValue}</S.StyledValue>}
          <S.StyledRadio
            id={name}
            name={name}
            onClick={() => setCantRate(!cantrate)}
            defaultChecked={cantrate}
            label={intl.formatMessage({
              description: "Assessment - Rate - Can't Rate Copy",
              defaultMessage: 'Cannot rate',
            })}
            mobileType="row"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
